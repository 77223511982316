import React from "react";
const Company = React.lazy(() =>
  import("../views/pages/superadmin/company/Company")
);
const newCompany = React.lazy(() =>
  import("../views/pages/superadmin/company/New")
);
const editCompany = React.lazy(() =>
  import("../views/pages/superadmin/company/Edit")
);

const monitorDashboard = React.lazy(() =>
  import("../views/pages/superadmin/dashboard/monitor")
);
const bankMaster = React.lazy(() =>
  import("../views/pages/superadmin/bank/bankMaster")
);
const balanceMaster = React.lazy(() =>
  import("../views/pages/superadmin/balance/balanceMaster")
);
const detailCompany = React.lazy(() =>
  import("../views/pages/superadmin/company/Detail")
);

const newsMaster = React.lazy(() =>
  import("../views/pages/superadmin/news/newsInfoMaster")
);
const newNewsMaster = React.lazy(() =>
  import("../views/pages/superadmin/news/newNews")
);
const editNewsMaster = React.lazy(() =>
  import("../views/pages/superadmin/news/editNews")
);
const detailNewsMaster = React.lazy(() =>
  import("../views/pages/superadmin/news/detailNews")
);
const newInfoMaster = React.lazy(() =>
  import("../views/pages/superadmin/news/newInfo")
);
const editInfoMaster = React.lazy(() =>
  import("../views/pages/superadmin/news/editInfo")
);
const detailInfoMaster = React.lazy(() =>
  import("../views/pages/superadmin/news/detailInfo")
);
const accountCompanyMaster = React.lazy(() =>
  import("../views/pages/superadmin/company/accountBank/account")
);
// const masterDashboard = React.lazy(() =>
//   import("../views/pages/superadmin/dashboard/dashboard")
// );
const masterGateway = React.lazy(() =>
  import("../views/pages/superadmin/gateway/gateway")
);
const newMasterGateway = React.lazy(() =>
  import("../views/pages/superadmin/gateway/new")
);
const customNameBalanceMaster = React.lazy(() =>
  import("../views/pages/superadmin/company/customNameBalance")
);
const masterDevice = React.lazy(() =>
  import("../views/pages/superadmin/device/device")
);
const settingAdminMenu = React.lazy(() =>
  import("../views/pages/superadmin/company/settingMenu/admin/menuAdmin")
);
const settingMemberMenu = React.lazy(() =>
  import("../views/pages/superadmin/company/settingMenu/member/menuMember")
);
const settingMerchantMenu = React.lazy(() =>
  import("../views/pages/superadmin/company/settingMenu/merchant/menuMerchant")
);
const limitTopupCompany = React.lazy(() =>
  import("../views/pages/superadmin/company/limit/limitTopup")
);
const configLoginCompany = React.lazy(() =>
  import("../views/pages/superadmin/company/config/login")
);
const masterFinancialManagement = React.lazy(() =>
  import("../views/pages/superadmin/financeManagement/management")
);
const masterCompanyBill = React.lazy(() =>
  import("../views/pages/superadmin/financeManagement/companyBill")
);
const masterWithdrawCompany = React.lazy(() =>
  import("../views/pages/superadmin/financeManagement/withdrawCompany")
);
const masterDetailWithdrawCompany = React.lazy(() =>
  import("../views/pages/superadmin/financeManagement/detailWithdrawCompany")
);
const masterBankWithdrawCompany = React.lazy(() =>
  import("../views/pages/superadmin/financeManagement/accountBank")
);
const masterHistoryPayment = React.lazy(() =>
  import("../views/pages/superadmin/financeManagement/historyPayment")
);
const configTopUpVaMethodSuperadmin = React.lazy(() =>
  import("../views/pages/superadmin/company/configVaTopupMethod/configVaTopup")
);

const configTimeWithdrawalSuperadmin = React.lazy(() =>
  import("../views/pages/superadmin/company/withdrawal/configWithdrawal")
);
const autoPayInvoiceConfiguration = React.lazy(() =>
  import("../views/pages/superadmin/company/autoPayInvoice/autoPayInvoice")
);
const lmsManagementSuperadmin = React.lazy(() =>
  import("../views/pages/superadmin/lms/lmsManagement")
);
const configTimeQris = React.lazy(() =>
  import("../views/pages/superadmin/company/configQris/configQris")
);

const configPasswordStrength = React.lazy(() =>
  import("../views/pages/superadmin/company/passwordConfig/passwordConfig")
);
const logTopupVA = React.lazy(() =>
  import("../views/pages/superadmin/log/logTopupVa")
);
const logBank = React.lazy(() =>
  import("../views/pages/superadmin/log/logBank")
);
const logPPOB = React.lazy(() =>
  import("../views/pages/superadmin/log/logPPOB")
);
const configPoweredBy = React.lazy(() =>
  import("../views/pages/superadmin/company/configPoweredby/configPoweredby")
);

const superadminRoutes = [
  {
    path: "/dashboard-monitor",
    element: monitorDashboard,
  },

  {
    path: "/master-bank",
    element: bankMaster,
  },
  {
    path: "/master-balance",
    element: balanceMaster,
  },
  { path: "/master-company", element: Company },
  { path: "/master-company/new", element: newCompany },
  { path: "/master-company/edit/:id", element: editCompany },
  { path: "/master-company/detail/:id", element: detailCompany },
  {
    path: "/master-news",
    element: newsMaster,
  },
  {
    path: "/master-news/new-news",
    element: newNewsMaster,
  },
  {
    path: "/master-news/edit-news/:id",
    element: editNewsMaster,
  },
  {
    path: "/master-news/detail-news/:id",
    element: detailNewsMaster,
  },
  {
    path: "/master-news/new-info",
    element: newInfoMaster,
  },
  {
    path: "/master-news/edit-info/:id",
    element: editInfoMaster,
  },
  {
    path: "/master-news/detail-info/:id",
    element: detailInfoMaster,
  },
  {
    path: "/master-company/account/:id",
    element: accountCompanyMaster,
  },
  // {
  //   path: "/master-dashboard",
  //   element: masterDashboard,
  // },
  {
    path: "/master-gateway",
    element: masterGateway,
  },
  {
    path: "/master-gateway/new/:id",
    element: newMasterGateway,
  },
  {
    path: "/master-company/custom-name-balance/:id",
    element: customNameBalanceMaster,
  },
  {
    path: "/master-device",
    element: masterDevice,
  },
  {
    path: "/master-company/setting-admin-menu/:id",
    element: settingAdminMenu,
  },
  {
    path: "/master-company/setting-member-menu/:id",
    element: settingMemberMenu,
  },
  {
    path: "/master-company/setting-merchant-menu/:id",
    element: settingMerchantMenu,
  },
  {
    path: "/master-company/limit-topup/:id",
    element: limitTopupCompany,
  },
  {
    path: "/master-company/config-login/:id",
    element: configLoginCompany,
  },
  {
    path: "/master-financial-management",
    element: masterFinancialManagement,
  },
  {
    path: "/master-financial-management/company-bill",
    element: masterCompanyBill,
  },
  {
    path: "/master-financial-management/withdraw-company",
    element: masterWithdrawCompany,
  },
  {
    path: "/master-financial-management/withdraw-company/detail/:id",
    element: masterDetailWithdrawCompany,
  },
  {
    path: "/master-financial-management/withdraw-company/bank-account",
    element: masterBankWithdrawCompany,
  },
  {
    path: "/master-financial-management/company-bill/payment-history/:id",
    element: masterHistoryPayment,
  },
  {
    path: "/master-company/config-va-topup-method/:id",
    element: configTopUpVaMethodSuperadmin,
  },

  {
    path: "/master-company/config-withdrawal/:id",
    element: configTimeWithdrawalSuperadmin,
  },
  {
    path: "/master-company/auto-payment-invoice-configuration/:id",
    element: autoPayInvoiceConfiguration,
  },
  {
    path: "/master-lms",
    element: lmsManagementSuperadmin,
  },
  {
    path: "/master-company/qris-settlement-time/:id",
    element: configTimeQris,
  },
  {
    path: "/master-company/password-strength-configuration/:id",
    element: configPasswordStrength,
  },
  {
    path: "/log-topup-va",
    element: logTopupVA,
  },
  {
    path: "/log-bank",
    element: logBank,
  },
  {
    path: "/log-ppob",
    element: logPPOB,
  },
  {
    path: "/master-company/config-poweredby/:id",
    element: configPoweredBy,
  },
];

export default superadminRoutes;
