import { formatCurrency } from "src/config/global";

export const ValidateSuccess = (respon) => {
  if (respon.type == "SUCCESS_LOGOUT") {
    return "Berhasil Logout";
  } else if (respon.type == "SUCCESS_LOGIN") {
    return "Berhasil Login";
  } else if (respon.type == "SUCCESS_REQUEST_OTP") {
    return "Permintaan OTP Berhasil";
  } else if (respon.type == "SUCCESS_VALIDATE_OTP") {
    return "Validasi OTP Berhasil";
  } else if (respon.type == "SUCCESS_VALIDATE_SECURITY_CODE") {
    return "Validasi Kode Keamanan Berhasil";
  } else if (respon.type == "SUCCESS_VALIDATE_SECURITY_CODE_TOKEN") {
    return "Validasi Kode Keamanan Token Berhasil";
  } else if (respon.type == "SUCCESS_GET_OTP_CONFIG") {
    return "Konfigurasi OTP Berhasil Didapatkan";
  } else if (respon.type == "SUCCESS_UPDATE_OTP_CONFIG") {
    return "Konfigurasi OTP Berhasil Diubah";
  } else if (respon.type == "SUCCESS_CREATED") {
    return "Data berhasil ditambahkan";
  } else if (respon.type == "SUCCESS_UPDATED") {
    return "Data berhasil diubah";
  } else if (respon.type == "SUCCESS_DELETED") {
    return "Data berhasil dihapus";
  } else if (respon.type == "PASSWORD_RESET") {
    return "Reset Password Berhasil";
  } else if (respon.type == "PASSWORD_UPDATED") {
    return "Password Berhasil Diubah";
  } else if (respon.type == "SUCCESS_CREATE_CREDENTIAL") {
    return "Berhasil Membuat Credential";
  } else if (respon.type == "INQUIRY_PAID") {
    return "Pembayaran Berhasil";
  } else if (respon.type == "SUCCESS_CREATE_CONFIG") {
    return "Konfig berhasil dibuat";
  } else if (respon.type == "SUCCESS_CREATE_ORDER_RECEIVER") {
    return "Data order receiver berhasil ditambahkan";
  } else if (respon.type == "SUCCESS_UPDATE_ORDER_RECEIVER") {
    return "Data order receiver berhasil diubah";
  } else if (respon.type == "OK") {
    return "Berhasil";
  } else if (respon.type == "") {
    return "Berhasil";
  } else {
    return respon.type;
  }
};

export const ValidateError = (respon, config) => {
  // console.log(respon.detail.status_code, respon.detail.type, "tes");
  let detail = respon;
  if (typeof respon.detail === "object") detail = respon.detail;
  switch (parseInt(detail.status_code)) {
    case 400:
      //console.log(detail);
      if (detail.type == "INVALID_COMPANY") {
        return "Company Tidak Valid";
      } else if (detail.type == "INACTIVE_COMPANY") {
        return "Company Tidak Aktif";
      } else if (detail.type == "USER_NOT_FOUND") {
        return "Username/Password salah";
      } else if (detail.type == "COMPANY_HAS_NOT_MADE_CARD_CONFIG") {
        return "Company belum membuat config kartu";
      } else if (detail.type == "ONLY_CAN_CREATE_AN_IDENTITY_CARD") {
        return "Hanya Dapat Menambah Kartu Identitas";
      } else if (detail.type == "ALL_MEMBER_ALREADY_HAVE_A_CARD") {
        return "Semua Member Sudah Memiliki Kartu";
      } else if (detail.type == "USER_INACTIVE") {
        return detail.message;
      } else if (detail.type == "WRONG_PASSWORD_COUNT_EXCEEDED") {
        return "Percobaan Melebihi Batas, Silahkan Reset Password Anda";
      } else if (detail.type == "AUTHENTICATION_FAILED") {
        return "Otentikasi Gagal";
      } else if (detail.type == "OTP_NOT_FOUND") {
        return detail.message;
      } else if (detail.type == "SECURITY_CODE_NOT_MATCH") {
        return "Kode Keamanan Tidak Cocok";
      } else if (detail.type == "USER_DO_NOT_HAVE_SECURITY_CODE") {
        return "Pengguna Tidak Memiliki Kode Keamanan";
      } else if (detail.type == "SECURITY_CODE_TOKEN_NOT_FOUND") {
        return "Token Kode Keamanan Tidak Ditemukan";
      } else if (detail.type == "BAD_REQUEST") {
        return detail.message;
      } else if (detail.type == "INVALID_DATE_FORMAT") {
        return "Format Tanggal Tidak Valid";
      } else if (detail.type == "INVALID_PHONE_NUMBER") {
        return "Nomor telepon tidak valid. Nomor telepon panjangnya harus 8-13 karakter";
      } else if (detail.type == "INVALID_EMAIL") {
        return "Email Tidak Sesuai, Isi Email Dengan Benar";
      } else if (detail.type == "INVALID_OBJECT_ID") {
        return "ID Obyek Tidak Valid";
      } else if (detail.type == "INVALID_PASSWORD_LENGTH") {
        return "Panjang Password Tidak Valid";
      } else if (detail.type == "FAILED_TO_REQUEST") {
        return "Gagal Meminta";
      } else if (detail.type == "COMPANY_INITIAL_OR_NAME_EXIST") {
        return "Inisial atau Nama Perusahaan Ada";
      } else if (detail.type == "DUPLICATE_EMAIL") {
        return detail.message;
      } else if (detail.type == "TOKEN_EXPIRED") {
        return "Token Kadaluwarsa";
      } else if (detail.type == "TOKEN_ALREADY_USED") {
        return "Token Sudah Digunakan";
      } else if (detail.type == "WRONG_CONFIRMATION_PASSWORD") {
        return "Password Konfirmasi Salah";
      } else if (detail.type == "WRONG_PASSWORD") {
        return "Password Salah";
      } else if (detail.type == "USERNAME_ALREADY_EXIST") {
        return "Username Tidak Boleh Sama";
      } else if (detail.type == "INVALID_SIZE_IMAGE") {
        return "Dimensi gambar tidak boleh lebih dari 1063x650";
      } else if (detail.type == "CARD_BLOCKED") {
        return "Kartu Sudah Terblokir";
      } else if (detail.type == "INVALID_NOMINAL") {
        return "Nominal tidak boleh kurang dari atau sama dengan 0";
      } else if (detail.type == "USER_ALREADY_HAVE_BALANCE") {
        return "Gagal Menghapus Konfigurasi Saldo, Pengguna Masih Memiliki Saldo";
      } else if (detail.type == "RESOURCE_ALREADY_EXIST") {
        return "Konfigurasi Saldo Sudah Tersedia";
      } else if (
        detail.type ==
        "START_DATE_END_DATE_DIFFERENCE_MUST_BE_LESS_THAN_OR_EQUAL_TO_THIRTY_DAYS"
      ) {
        return "Rentang tanggal maksimal 30 hari";
      } else if (detail.type == "PAYMENT_METHOD_OPTION_NOT_YET_SUPPORTED") {
        return "Metode Pembayaran Tidak Tersedia";
      } else if (
        detail.type == "DEFAULT_MINUTES_UNTIL_EXPIRED_MUST_BE_BIGGER_THAN_ZERO"
      ) {
        return "Batas Waktu belum di masukkan";
      } else if (detail.type == "ALREADY_EXISTS") {
        return "Data Sudah Ada";
      } else if (detail.type === "DISCOUNT_CANNOT_BE_LESS_THAN_ZERO") {
        return "Diskon Harus lebih besar Dari Nol";
      } else if (
        detail.type === "AMOUNT_IN_DETAIL_ITEM_INVOICE_MUST_BE_BIGGER_THAN_ZERO"
      ) {
        return "Nominal di Detail Invoice Harus lebih besar Dari Nol";
      } else if (
        detail.type === "EXPIRED_DATE_MUST_BE_LATER_OR_EQUAL_TO_INVOICE_DATE"
      ) {
        return "Tanggal Jatuh Tempo Harus lebih dari atau sama dengan Tanggal Invoice";
      } else if (
        detail.type ===
        "EXPIRED_DATE_MUST_BE_LATER_THAN_OR_EQUAL_TO_THE_CURRENT_DATE"
      ) {
        return "Tanggal Jatuh Tempo Harus lebih dari atau sama dengan Tanggal Saat ini";
      } else if (
        detail.type ===
        "INVOICE_DATE_MUST_BE_LATER_OR_THE_SAME_AS_THE_CURRENT_DATE"
      ) {
        return "Tanggal Invoice Harus lebih dari atau sama dengan Tanggal Saat ini";
      } else if (
        detail.type === "AMOUNT_MINUS_DISCOUNT_CANNOT_BE_LESS_THAN_ZERO"
      ) {
        return "Nominal Dikurangi Diskon Harus lebih besar Dari Nol";
      } else if (
        detail.type === "TOTAL_AMOUNT_INVOICE_CANNOT_BE_ZERO_OR_LESS_THAN_ZERO"
      ) {
        return "Total Invoice harus lebih besar Dari Nol";
      } else if (
        detail.type === "INVOICE_DATE_CANNOT_BE_NULL_IF_IS_REPEATING_IS_FALSE"
      ) {
        return "Tanggal Invoice Harus Diisi Apabila Fitur Berulang Tidak Aktif";
      } else if (
        detail.type === "EXPIRED_DATE_CANNOT_BE_NULL_IF_IS_REPEATING_IS_FALSE"
      ) {
        return "Tanggal Jatuh Tempo Harus Diisi Apabila Fitur Berulang Tidak Aktif";
      } else if (
        detail.type ===
        "REPEATING_UNLIMITED_CANNOT_BE_TRUE_AND_REPEATING_METHOD_MAKE_ALL_AT_THE_SAME_TIME"
      ) {
        return "Fitur Berulang Tak Terbatas dan Metode Kemunculan Invoice Tidak Bisa Diaktifkan Bersamaan";
      } else if (detail.type === "REPEATING_RANGE_MUST_BE_BIGGER_THAN_ZERO") {
        return "Durasi Perulangan Harus lebih Dari Nol";
      } else if (
        detail.type ===
        "RANGE_BETWEEN_INVOICE_DATE_AND_EXPIRED_DATE_MUST_BE_BIGGER_OR_EQUAL_TO_ZERO"
      ) {
        return "Rentang Tanggal Invoice dan Tanggal Jatuh Tempo harus lebih besar Dari Nol";
      } else if (detail.type === "REPEATING_START_DATE_CANNOT_BE_NULL") {
        return "Tanggal Mulai Perulangan Tidak Boleh Kosong";
      } else if (detail.type === "REPEATING_END_DATE_CANNOT_BE_NULL") {
        return "Tanggal Selesai Perulangan Tidak Boleh Kosong";
      } else if (
        detail.type === "REPEATING_START_DATE_MUST_BE_LATER_THAN_CURRENT_DATE"
      ) {
        return "Tanggal Mulai Perulangan Harus lebih Dari Tanggal Saat ini";
      } else if (
        detail.type ===
        "REPEATING_END_DATE_MUST_BE_LATER_OR_EQUAL_TO_REPEATING_START_DATE"
      ) {
        return "Tanggal Selesai Perulangan Harus lebih Dari Tanggal Saat ini";
      } else if (
        detail.type ===
        "REPEATING_RANGE_MUST_BE_BIGGER_THAN_RANGE_BETWEEN_INVOICE_DATE_AND_EXPIRED_DATE"
      ) {
        return "Durasi Perulangan Harus lebih besar Dari Rentang Tanggal Invoice dan Tanggal Jatuh Tempo";
      } else if (detail.type === "REPEATING_START_MONTH_CANNOT_BE_NULL") {
        return "Bulan Mulai Perulangan Tidak Boleh Kosong";
      } else if (detail.type === "REPEATING_END_MONTH_CANNOT_BE_NULL") {
        return "Bulan Selesai Perulangan Tidak Boleh Kosong";
      } else if (detail.type === "REPEATING_START_YEAR_CANNOT_BE_NULL") {
        return "Tahun Mulai Perulangan Tidak Boleh Kosong";
      } else if (detail.type === "REPEATING_END_YEAR_CANNOT_BE_NULL") {
        return "Tahun Selesai Perulangan Tidak Boleh Kosong";
      } else if (detail.type === "REPEATING_DATE_OF_MONTH_CANNOT_BE_NULL") {
        return "Tanggal Perulangan Tidak Boleh Kosong";
      } else if (
        detail.type ===
        "REPEATING_START_MONTH_YEAR_MUST_BE_LESS_OR_EQUAL_TO_REPEATING_END_MONTH_YEAR"
      ) {
        return "Bulan Tahun Mulai Perulangan Harus kurang dari atau sama dengan Bulan Tahun Selesai Perulangan";
      } else if (
        detail.type ===
        "REPEATING_START_MONTH_YEAR_MUST_BE_LATER_THAN_CURRENT_MONTH_YEAR"
      ) {
        return "Bulan Tahun Mulai Perulangan Harus lebih dari Bulan Tahun Saat ini";
      } else if (
        detail.type === "REPEATING_DATE_OF_MONTH_MUST_BE_BETWEEN_1_AND_28"
      ) {
        return "Tanggal Perulangan Harus Diantara 1 dan 28";
      } else if (detail.type === "INVOICE_ALREADY_CREATED_FROM_MASTER") {
        return "Invoice Sudah Terbuat dari Master";
      } else if (detail.type === "INVOICE_ALREADY_SCHEDULED") {
        return "Tagihan sudah dijadwalkan untuk user tersebut";
      } else if (detail.type === "INVOICE_MUST_BE_UNPAID") {
        return "Invoice Harus Belum Terbayar";
      } else if (detail.type === "USER_CANNOT_CREATE_INVOICE_FOR_SELF") {
        return "User Tidak Bisa Membuat Invoice Untuk Dirinya Sendiri";
      } else if (detail.type === "INVOICE_CUSTOMER_IS_NOT_CURRENT_USER") {
        return "User Tertagih Bukan User Saat Ini";
      } else if (detail.type === "INVOICE_CREATOR_IS_NOT_CURRENT_USER") {
        return "User Pembuat Invoice Bukan User Saat Ini";
      } else if (
        detail.type ===
        "INVOICE_COMPANY_IS_NOT_THE_SAME_AS_CURRENT_USER_COMPANY"
      ) {
        return "Invoice Perusahaan Tidak Sama Dengan Perusahaan User Saat ini";
      } else if (detail.type === "INVOICE_MUST_BE_PAID") {
        return "Invoice Harus Terbayar";
      } else if (
        detail.type === "INPUT_AMOUNT_MUST_BE_SMALLER_OR_EQUAL_TO_INVOICE_FINAL"
      ) {
        return "Nominal Harus lebih kecil atau sama dengan Total Invoice";
      } else if (detail.type === "AMOUNT_MUST_BE_BIGGER_THAN_ZERO") {
        return "Nominal Harus lebih besar dari Nol";
      } else if (detail.type === "INVOICE_ALREADY_PAID") {
        return "Invoice Telah Terbayar";
      } else if (detail.type === "INVOICE_IS_UNPAID") {
        return "Invoice Belum Terbayar";
      } else if (detail.type === "MASTER_INVOICE_MUST_BE_ACTIVE") {
        return "Invoice Master Harus Aktif";
      } else if (
        detail.type ===
        "INVOICE_LINK_USER_ID_MUST_BE_THE_SAME_AS_INVOICE_USER_ID"
      ) {
        return "Link Id Harus sama dengan User Id Tertagih";
      } else if (detail.type === "FIELD_NOT_EXIST") {
        return "Field Tidak Tersedia";
      } else if (
        detail.type ===
        "START_DATE_END_DATE_DIFFERENCE_MUST_BE_LESS_OR_EQUAL_TO_THIRTY_DAYS"
      ) {
        return "Perbedaan Rentang Tanggal Mulai Dan Tanggal Selesai Harus lebih sedikit atau sama dengan 30 hari";
      } else if (detail.type === "INVALID_COLUMN") {
        return "Kolom Tidak Valid";
      } else if (detail.type === "UPLOADED_EXCEL_HAVE_INVALID_COLUMNS") {
        return "Berkas Excel yang Diunggah memiliki Kolom Tidak Valid";
      } else if (detail.type === "TOPIC_MUST_CONFORM_TO_AGREED_FORMAT") {
        return "Topik Harus Menyesuaikan Format Kesepakatan";
      } else if (detail.type === "DESTINATION_BALANCE_NOT_ALLOWED") {
        return "Saldo Tujuan Tidak Diperbolehkan";
      } else if (detail.type === "INVOICE_IS_NOT_CREATED_BY_GATEWAY") {
        return "Invoice Tidak Terbuat oleh Gateway";
      } else if (detail.type === "EMPTY_REQUEST_VALUE") {
        return detail.message;
      } else if (detail.type === "INVALID_PASSWORD_CRITERIA") {
        return "Password Minimal 6-30 Karakter, 1 huruf besar, 1  huruf kecil, 1 karakter spesial, dan 1 angka";
      } else if (detail.type === "NOID_ALREADY_EXISTS") {
        return "Nomor ID Sudah Terdaftar";
      } else if (detail.type === "TIME_PROCESSING_MIN_1_DAY") {
        return "Waktu Auto-Confirmed Minimal 1 Hari";
      } else if (detail.type === "USERNAME_TOO_SHORT") {
        return "Username Minimal 6 Karakter";
      } else if (detail.type == "PROVIDER_TROUBLE") {
        return "Terjadi masalah penyedia layanan, silahkan coba beberapa saat lagi";
      } else if (detail.type == "FAILED_CREATE_VA") {
        return "Gagal membuat virtual account";
      } else if (
        detail.type ==
        "AMOUNT_IS_BIGGER_THAN_REMAINING_AMOUNT_THAT_CAN_BE_BILLED"
      ) {
        return "Nominal lebih besar dari sisa jumlah yang dapat ditagihkan";
      } else if (detail.type == "AMOUNT_IS_BIGGER_THAN_REMAINING_BILL_AMOUNT") {
        return "Nominal pembayaran melebihi sisa tagihan yang ada";
      } else if (detail.type == "BILLING_ALREADY_PAID") {
        return "Tagihan sudah lunas";
      } else if (detail.type === "BALANCE_CANNOT_BE_LESS_THAN_ZERO") {
        return "Saldo tidak mencukupi";
      } else if (
        detail.type == "CHARGE_PERCENTAGE_VALUE_MUST_BE_LESS_THAN_ONE_HUNDRED"
      ) {
        return "Nilai presentase biaya harus kurang dari 100";
      } else if (detail.type == "VALUE_MUST_BE_BIGGER_THAN_ZERO") {
        return "Harus lebih dari 0";
      } else if (
        detail.type == "ONLY_ONE_CONFIG_WITHDRAW_MERCHANT_ACTIVE_PER_COMPANY"
      ) {
        return "Hanya diizinkan mengaktifkan satu metode";
      } else if (detail.type == "UPLOADED_EXCEL_HAVE_INVALID_COLUMN") {
        return "Template tidak sesuai";
      } else if (detail.type == "CARD_UNBLOCKED") {
        return detail.message;
      } else if (detail.type == "NUMBER_OF_DAYS_CANNOT_BE_MORE_THAN_31") {
        return "Tanggal tidak boleh lebih dari satu bulan";
      } else if (detail.type == "COMPANY_HAS_MADE_CARD_PRICES") {
        return "Hanya bisa menambahkan satu harga kartu";
      } else if (detail.type === "DEVICE_CODE_ALREADY_EXISTS") {
        return "Kode Device sudah ada";
      } else if (detail.type == "NULL_CARD_IDENTIFIER") {
        return "Wajib mengisi data diri kartu";
      } else if (detail.type == "INVALID_COLUMS_EXCEL") {
        return "Template tidak sesuai";
      } else if (detail.type === "INVALID_DATE_RANGE") {
        return "Rentang tanggal maksimal 30 hari";
      } else if (detail.type == "EMPTY_NOID") {
        return "No ID tidak boleh kosong";
      } else if (detail.type == "CARDS_ALREADY_HAVE_PIN") {
        return "Kartu sudah tidak memiliki pin";
      } else if (detail.type == "CARD_ALREADY_HAVE_PIN") {
        return "Kartu sudah memiliki pin";
      } else if (detail.type == "CARDS_HAS_NO_PIN") {
        return "Kartu sudah tidak memiliki pin";
      } else if (detail.type == "COMPANY_ALREADY_HAVE_CONFIG_BUTTON_ORDER") {
        return "Company sudah membuat config tombol order";
      } else if (detail.type == "DUPLICATE_PHONE_NUMBER") {
        return "Nomor Telepon Sudah Terdaftar";
      } else if (detail.type == "FAILED_TO_CREATE_VA") {
        return "Gagal membuat virtual account";
      } else if (detail.type == "STILL_HAVE_A_DEVICE") {
        return "Aktifitas masih memiliki device";
      } else if (detail.type == "DESTINATION_USER_EMAIL_NOT_FOUND") {
        return "User tujuan tidak memiliki email";
      } else if (
        detail.type == "AMOUNT_MINUS_TOTAL_CHARGES_MUST_BE_BIGGER_THAN_ZERO"
      ) {
        return `Minimal nominal harus diatas ${formatCurrency(
          detail.message?.totalCharges
        )}`;
      } else if (detail.type == "TOP_UP_AMOUNT_AND_TRANSFER_AMOUNT_NOT_EQUAL") {
        return "Nominal top up dan nominal transfer tidak sama";
      } else if (detail.type == "USER_IS_NOT_ACTIVE") {
        return "Pengguna tidak aktif";
      } else if (detail.type == "MEMBER_IS_NOT_ACTIVE") {
        return "Member tidak aktif";
      } else if (detail.type == "MERCHANT_IS_NOT_ACTIVE") {
        return "Merchant tidak aktif";
      } else if (detail.type == "IMPORT_USER_WORKSHEET_NOT_FOUND") {
        return "Worksheet member tidak ditemukan";
      } else if (detail.type == "RESERVED_ADDITIONAL_DATA") {
        return detail.message;
      } else if (detail.type == "TOP_UP_AMOUNT_MORE_THAN_DAILY_LIMIT") {
        return "Nominal top up melebihi limit harian";
      } else if (detail.type == "CANT_DOWNLOAD_MORE_THAN_1_MONTH") {
        return detail.message;
      } else if (detail.type == "INVALID_VALUE") {
        return detail.message;
      } else if (detail.type == "TIME_MUST_GREATER") {
        return "Waktu selesai harus lebih besar dari waktu mulai";
      } else if (detail.type == "OLD_PASSWORD_IS_WRONG") {
        return "Password lama tidak sesuai";
      } else if (detail.type == "BALANCE_ALREADY_ENABLED_BY_COMPANY") {
        return detail.message;
      } else if (detail.type == "CHANGE_EMAIL_ADDRESS_IS_NOT_ALLOWED") {
        return detail.message;
      } else if (detail.type == "PHONE_NUMBER_ALREADY_REGISTERED") {
        return detail.message;
      } else if (detail.type == "EMAIL_ADDRESS_ALREADY_REGISTERED") {
        return detail.message;
      } else if (detail.type == "PREFIX_NOT_MATCH") {
        return "Username/Password salah";
      } else if (detail.type == "CONFIG_MENU_ALREADY_EXISTS") {
        return "Konfigurasi menu sudah ditambahkan";
      } else if (detail.type == "EXTENSION_NOT_ALLOWED") {
        return "Format file tidak sesuai";
      } else if (detail.type == "MAIN_BALANCE_CANNOT_BE_DELETED_OR_DISABLED") {
        return "Saldo Utama Tidak Dapat Dihapus Atau Dinonaktifkan";
      } else if (detail.type == "CANNOT_CHANGE_FINANCIAL_TYPE") {
        return detail.message;
      } else if (detail.type == "MIN_AMOUNT_MIN_10000") {
        return "Nominal Minimal Transaksi Rp. 10.000";
      } else if (detail.type == "CHARGE_PERCENTAGE_CANT_EXCEED_100") {
        return "Biaya tidak boleh lebih dari 100%";
      } else if (detail.type == "MAX_AMOUNT_MUST_GREATER_THAN_MIN_AMOUNT") {
        return "Nominal maksimal transaksi harus lebih dari nominal minimal transaksi";
      } else if (detail.type == "CHARGE_AMOUNT_MUST_POSITIVE") {
        return "Biaya tidak boleh (-) minus / negatif";
      } else if (detail.type == "AMOUNT_MUST_POSITIVE") {
        return "Biaya tidak boleh (-) minus / negatif";
      } else if (detail.type == "REACHED_MAX_MEMBER") {
        return detail.message;
      } else if (detail.type == "CONFIG_MENU_NOT_FOUND") {
        return detail.message;
      } else if (detail.type == "THERE_IS_STILL_TRANSACTION_CANT_DELETE") {
        return detail.message;
      } else if (
        detail.type == "MIN_AMOUNT_MUST_BE_SMALLER_OR_THE_SAME_AS_MAX_AMOUNT"
      ) {
        return "Nominal minimal top up harus lebih kecil atau sama dengan nominal maksimal top up";
      } else if (
        detail.type ==
        "MAX_AMOUNT_MUST_BE_SMALLER_OR_THE_SAME_THAN_DEFAULT_MAX_AMOUNT"
      ) {
        return `Nominal maksimal top up harus lebih kecil atau sama dari jumlah maksimal default yakni ${formatCurrency(
          detail.message?.defaultMaxAmount
        )}`;
      } else if (
        detail.type ==
        "MIN_AMOUNT_MUST_BE_BIGGER_OR_THE_SAME_THAN_DEFAULT_MIN_AMOUNT"
      ) {
        return `Nominal minimal top up harus lebih besar atau sama dari jumlah minimum default yakni ${formatCurrency(
          detail.message?.defaultMinAmount
        )}`;
      } else if (detail.type == "EXPIRED_TIME_MUST_BE_BIGGER_THAN_ZERO") {
        return "Expired VA harus lebih dari 0";
      } else if (detail.type == "MAX_BANK_ACCOUNT_COUNTS_REACHED") {
        return "Jumlah rekening bank melebihi batas maksimal";
      } else if (detail.type == "FAILED_TO_CHANGE_CONFIG") {
        return detail.message;
      } else if (detail.type == "PHONE_ALREADY_EXIST") {
        return detail.message;
      } else if (detail.type == "EMAIL_ALREADY_EXIST") {
        return detail.message;
      } else if (detail.type == "INTERVAL_MIN_ONE_HOUR") {
        return "Waktu interval minimal 1 jam";
      } else if (detail.type == "CODE_ALREADY_EXIST") {
        return "Kode unik tidak boleh sama";
      } else if (detail.type == "REQUIRED_TO_FILL_IN_CARD_DATA") {
        return detail.message;
      } else if (detail.type == "TRANSACTION_LIMIT_FAILURE") {
        return detail.message;
      } else if (detail.type == "ROLE_HAS_BEEN_SET") {
        return "Role sudah diatur";
      } else if (detail.type == "BILL_ALREADY_PAID") {
        return "Tagihan sudah terbayar";
      } else if (
        detail.type == "PAYMENT_AMOUNT_IS_BIGGER_THAN_BILL_REMAINING_AMOUNT"
      ) {
        return "Jumlah pembayaran melebihi jumlah tagihan";
      } else if (detail.type == "DONATION_NOT_GENERAL") {
        return detail.message;
      } else if (detail.type == "GENERAL_DONATION_ALREADY_EXISTS") {
        return "Donasi bertipe general sudah ada, Anda hanya diperbolehkan membuat 1 donasi bertipe general";
      } else if (detail.type == "INVALID_USERNAME_OR_PASSWORD") {
        return detail.message;
      } else if (detail.type == "NOID_ALREADY_EXISTS_IN_USERNAME") {
        return detail.message;
      } else if (detail.type == "USERNAME_ALREADY_EXIST_IN_NOID") {
        return detail.message;
      } else if (detail.type == "FAILED_TO_DELETE_AID") {
        return detail.message;
      } else if (detail.type == "SETTLEMENT_HOURS_MUST_BE_BIGGER_THAN_ZERO") {
        return "Waktu harus lebih dari 0";
      } else if (detail.type == "ENTRANCE_STATE_MUST_NOT_NULL") {
        return detail.message;
      } else if (detail.type == "ENTRANCE_RECORD_NOT_FOUND") {
        return detail.message;
      } else if (detail.type == "CANNOT_ADD_SAME_VECICLE_TYPE") {
        return detail.message;
      } else if (
        detail.type == "CANNOT_ADD_SAME_VECICLE_TYPE_AND_MEMBER_TAGS"
      ) {
        return detail.message;
      } else if (detail.type == "CASHBACK_MUST_NOT_HAVE_SAME_TYPE") {
        return detail.message;
      } else if (detail.type == "MAX_CASHBACK_IS_TOO_MUCH") {
        return detail.message;
      } else {
        return detail.type;
      }
    case 401:
      if (detail.type == "WRONG_PASSWORD") {
        return "Username/Password salah";
      } else if (detail.type == "AUTHENTICATION_FAILED") {
        return "Otentikasi Gagal";
      } else if (detail.type == "UNAUTHORIZED") {
        return "Tidak Diizinkan";
      } else if (detail.type == "UNATHORIZED") {
        return "Sesi Telah Berakhir";
      } else {
        return detail.type;
      }
    case 403:
      if (detail.type == "UNAUTHORIZED_ROLE") {
        return "Akses Tidak Diizinkan";
      } else if (detail.type == "ACCESS_FORBIDDEN") {
        return "Dilarang Akses";
      } else if (detail.type == "OTP_REQUIRED") {
        return "Harap masukkan OTP";
      } else if (detail.type === "SUPERADMIN_MUST_CHANGE_COMPANY") {
        return;
      } else if (detail.type === "SELF_ADD_SUB_ADMIN_COMPANY_FORBIDDEN") {
        return detail.message;
      } else if (detail.type === "ADMIN_SUB_COMPANY_ACCESS_FORBIDDEN") {
        return detail.message;
      } else {
        return detail.type;
      }
    case 404:
      if (detail.type == "NOT_FOUND") {
        return "Tidak Ditemukan";
      } else if (detail.type == "COMPANY_NOT_FOUND") {
        return "Perusahaan Tidak Ditemukan";
      } else if (detail.type == "COMPANY_PIC_NOT_FOUND") {
        return "Data PIC Perusahaan Tidak Ditemukan";
      } else if (detail.type === "QR_CODE_NOT_FOUND") {
        return "Barcode tidak ditemukan";
      } else if (detail.type === "NOT_FOUND") {
        return "Tidak Ditemukan";
      } else if (detail.type === "TEMPLATE_NOT_FOUND") {
        return "Template tidak ditemukan";
      } else if (detail.type === "ADMIN_NOT_FOUND") {
        return "Admin Tidak Ditemukan";
      } else if (detail.type === "MEMBER_NOT_FOUND") {
        return "Member Tidak Ditemukan";
      } else if (detail.type === "MERCHANT_NOT_FOUND") {
        return "Merchant Tidak Ditemukan";
      } else if (detail.type === "USER_NOT_FOUND") {
        return "Username/Password Salah";
      } else if (detail.type === "INVOICE_TRANSACTION_NOT_FOUND") {
        return "Transksi Invoice Tidak Ditemukan";
      } else if (detail.type === "INVOICE_LINK_NOT_FOUND") {
        return "Link Invoice Tidak Ditemukan";
      } else if (detail.type === "MASTER_INVOICE_NOT_FOUND") {
        return "Invoice Master Tidak Ditemukan";
      } else if (detail.type === "INVOICE_NOT_FOUND") {
        return "Invoice Tidak Ditemukan";
      } else if (detail.type == "CARD_NOT_FOUND") {
        return "Kartu tidak ditemukan";
      } else if (detail.type == "COMPANY_CONFIG") {
        return detail.message;
      } else if (detail.type == "EMAIL_NOTIFICATION_NOT_FOUND") {
        return detail.message;
      } else {
        return detail.type;
      }
    case 415:
      if (detail.type == "UNSUPPORTED_MEDIA_TYPE") {
        return "Format file tidak sesuai";
      } else {
        return detail.message;
      }
    case 409:
      if (detail.type == "EMAIL_ALREADY_SET_ON_OTHER_USER") {
        return "Email sudah digunakan oleh pengguna lain";
      } else {
        return detail.message;
      }
    case 500:
      if (detail.type == "INTERNAL_SERVER_ERROR") {
        return "Kesalahan Server";
      } else {
        return detail.type;
      }
    default:
      return detail.type;
  }
};
